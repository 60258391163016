<template>
  <div class="header-contact">
    <div class="header-dom"></div>
    <div class="some-dom flex-row flex-center">
      <!-- <div class="flex-col left-box">
        <h1>联系我们</h1>
        <div class="flex-col">
          <p class="flex-row">
            <span class="left-span">商务合作</span>
            <span class="mx-4">XXX@seekshine.com</span>
          </p>
          <p class="flex-row">
            <span class="left-span">微信</span>
            <i class="mx-4"></i>
          </p>
        </div>
      </div> -->
      <div class="flex-col right-box">
        <h1>联系我们</h1>
        <el-form
          ref="form"
          :model="formData"
          :rules="rules"
          label-width="20px"
          :inline="false"
          size="mini"
        >
          <el-form-item prop="name" label=" ">
            <el-input
              v-model="formData.name"
              class="my-2"
              placeholder="姓名"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item prop="phone" label=" ">
            <el-input
              class="my-2"
              v-model="formData.phone"
              placeholder="手机号"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item prop="gmail" label=" ">
            <el-input
              class="my-2"
              v-model="formData.gmail"
              placeholder="邮箱"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item prop="company" label=" ">
            <el-input
              class="my-2"
              v-model="formData.company"
              placeholder="公司名称"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item prop="remark">
            <el-input
              class="my-2"
              v-model="formData.remark"
              placeholder="备注"
              type="textarea"
              style="max-height:8vw;"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              class="my-4 button w-100"
              type="primary"
              @click="submitFun"
            >
              提交
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ElMessage } from "element-plus";
import { defineComponent, reactive, ref } from "vue";

export default defineComponent({
  setup() {
    const formData = reactive({
      name: "",
      phone: "",
      gmail: "",
      company: "",
      remark: "",
    });

    const form = ref<HTMLFormElement>();

    function submitFun() {
      if (form.value) {
        form.value.validate((bool: boolean) => {
          if (bool) {
            const queryUrl =
              (window as any).QueryBaseUrl +
              "/admin/guardadmin/createContactUs";
            if (typeof window.fetch == "function") {
              fetch(queryUrl, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                  name: formData.name,
                  tel: formData.phone,
                  email: formData.gmail,
                  corporateName: formData.company,
                  remark: formData.remark,
                }),
              })
                .then((data) => data.json())
                .then((data: { status: { code: any; message: any } }) => {
                  if (data.status && data.status.code == "1") {
                    ElMessage.success(data.status.message);
                    form.value?.resetFields();
                  } else {
                    ElMessage.warning(data.status.message);
                  }
                });
            } else {
              const xmlt = new XMLHttpRequest();
              xmlt.open("POST", queryUrl, true);
              xmlt.addEventListener("load", (res: any) => {
                const json = JSON.parse(res?.currentTarget?.response || "{}");
                if (json) {
                  if (json.status && json.status.code == "1") {
                    ElMessage.success(json.status.message);
                    form.value?.resetFields();
                  } else {
                    ElMessage.warning(json.status.message);
                  }
                } else {
                  ElMessage.warning("操作失败");
                }
              });
              xmlt.setRequestHeader("Content-Type", "application/json");
              xmlt.send(
                JSON.stringify({
                  name: formData.name,
                  tel: formData.phone,
                  email: formData.gmail,
                  corporateName: formData.company,
                  remark: formData.remark,
                })
              );
            }
          }
        });
      }
    }

    return {
      form,
      formData,
      submitFun,
      rules: {
        name: [{ required: true, message: "内容不能为空", trigger: "blur" }],
        phone: [{ required: true, message: "内容不能为空", trigger: "blur" }],
        gmail: [{ required: true, message: "内容不能为空", trigger: "blur" }],
        company: [{ required: true, message: "内容不能为空", trigger: "blur" }],
        // remark: [{ required: true ,message:'内容不能为空',}],
      },
    };
  },
});
</script>

<style lang="scss" scoped>
.header-contact {
  .header-dom {
    width: 100%; ///;
    height: 20.833vw;
    background-image: url(~@/assets/image/bg4.png);
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center;
  }
  :deep(.el-input__inner) {
    height: 32px;
  }

  .some-dom {
    height: 37.5vw;
    .left-box {
      span.left-span {
        width: 4.896vw;
      }
      i {
        display: inline-block;
        width: 6.25vw;
        height: 6.25vw;
        background-image: url(~@/assets/image/th/ewm.png);
        background-size: 100% 100%;
      }
    }
    .el-button--mini {
      padding: 12px 15px;
    }
    .right-box {
      // margin-left: 20vw;
      h1 {
        text-align: center;
      }
      width: 24vw;
      :deep(.el-form-item) {
        margin-bottom: 1vw;
      }
    }
  }
}
</style>
