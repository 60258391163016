
import { ElMessage } from "element-plus";
import { defineComponent, reactive, ref } from "vue";

export default defineComponent({
  setup() {
    const formData = reactive({
      name: "",
      phone: "",
      gmail: "",
      company: "",
      remark: "",
    });

    const form = ref<HTMLFormElement>();

    function submitFun() {
      if (form.value) {
        form.value.validate((bool: boolean) => {
          if (bool) {
            const queryUrl =
              (window as any).QueryBaseUrl +
              "/admin/guardadmin/createContactUs";
            if (typeof window.fetch == "function") {
              fetch(queryUrl, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                  name: formData.name,
                  tel: formData.phone,
                  email: formData.gmail,
                  corporateName: formData.company,
                  remark: formData.remark,
                }),
              })
                .then((data) => data.json())
                .then((data: { status: { code: any; message: any } }) => {
                  if (data.status && data.status.code == "1") {
                    ElMessage.success(data.status.message);
                    form.value?.resetFields();
                  } else {
                    ElMessage.warning(data.status.message);
                  }
                });
            } else {
              const xmlt = new XMLHttpRequest();
              xmlt.open("POST", queryUrl, true);
              xmlt.addEventListener("load", (res: any) => {
                const json = JSON.parse(res?.currentTarget?.response || "{}");
                if (json) {
                  if (json.status && json.status.code == "1") {
                    ElMessage.success(json.status.message);
                    form.value?.resetFields();
                  } else {
                    ElMessage.warning(json.status.message);
                  }
                } else {
                  ElMessage.warning("操作失败");
                }
              });
              xmlt.setRequestHeader("Content-Type", "application/json");
              xmlt.send(
                JSON.stringify({
                  name: formData.name,
                  tel: formData.phone,
                  email: formData.gmail,
                  corporateName: formData.company,
                  remark: formData.remark,
                })
              );
            }
          }
        });
      }
    }

    return {
      form,
      formData,
      submitFun,
      rules: {
        name: [{ required: true, message: "内容不能为空", trigger: "blur" }],
        phone: [{ required: true, message: "内容不能为空", trigger: "blur" }],
        gmail: [{ required: true, message: "内容不能为空", trigger: "blur" }],
        company: [{ required: true, message: "内容不能为空", trigger: "blur" }],
        // remark: [{ required: true ,message:'内容不能为空',}],
      },
    };
  },
});
